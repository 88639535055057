import { Button, Tooltip } from "@kaltura/ds-react-components";
import { GenAI24Icon } from "@kaltura/ds-react-icons";
import { baseUrl, fetchKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { useMediaDrawer } from "../../../../media-drawer/MediaDrawerContext";
import { GenerateAiQuiz, GenerateAiQuizResponse } from "../../../../generate-ai-quiz/GenerateAiQuiz";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { Suspense } from "react";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import { AiQuizGenerateError } from "../../../../generate-ai-quiz/AiQuizGenerateError";
import { AiQuizSkeleton } from "../../../../generate-ai-quiz/AiQuizSkeleton";
import { ExitConfirmation } from "../../../../generate-ai-quiz/ExitConfirmation";
import ReactHtmlParser from "@kaltura/mediaspace-shared-utils/html-react-parser";

export interface GenerateAiQuizCtaProps {
    playerConfig: any;
    entryId: string;
    hasCaptions: boolean;
}

/**
 * Generate AI quiz button
 */
export const GenerateAiQuizCta = ({ hasCaptions, playerConfig, entryId }: GenerateAiQuizCtaProps) => {
    const { open: openMediaDrawer } = useMediaDrawer();
    const sendButtonAnalytics = useButtonAnalytics();

    const generateAiQuiz = () => {
        sendButtonAnalytics("Quiz AI - Create", ButtonClickAnalyticsType.CREATE);
        const generateQuiz = fetchKmsData<GenerateAiQuizResponse>(
            `${baseUrl}/kalturaai/quiz/generate?entryId=${encodeURIComponent(entryId)}`
        );
        openMediaDrawer(
            <ErrorBoundary fallback={<AiQuizGenerateError />}>
                <Suspense
                    fallback={
                        <>
                            <AiQuizSkeleton />
                            <ExitConfirmation />
                        </>
                    }
                >
                    <GenerateAiQuiz generateQuiz={generateQuiz} playerConfig={playerConfig} />
                </Suspense>
            </ErrorBoundary>
        );
    };

    return (
        <Tooltip
            title={
                hasCaptions
                    ? translate("Ask Kaltura AI to generate a new quiz based on this video's captions")
                    : ReactHtmlParser(
                          translate(
                              "To create a quiz, captions are required.<br/>Please add captions to your media first."
                          )
                      )
            }
            placement="bottom"
        >
            {/* By default, disabled elements  do not trigger user interactions so a Tooltip will not activate on normal events like hover*/}
            {/*To accommodate disabled elements, we're adding a simple wrapper element*/}
            <span>
                <Button
                    id={"kms-ds-single-generate-ai-quiz"}
                    disabled={!hasCaptions}
                    size={"medium"}
                    startIcon={<GenAI24Icon />}
                    onClick={generateAiQuiz}
                >
                    {translate("AI quiz generator")}
                </Button>
            </span>
        </Tooltip>
    );
};

export default GenerateAiQuizCta;
