import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { NoResultsIllustration } from "@kaltura/mediaspace-shared-ui/styled-illustration/NoResultsIllustration";

export interface NoResultsProps {
    title: string;
}

const StyledNoResults = styled("div")({
    textAlign: "center",
});

/**
 * category search - no results found
 */
export function NoResults({ title }: NoResultsProps) {
    return (
        <StyledNoResults>
            <div>
                <NoResultsIllustration />
            </div>
            <Typography variant="h4">{title}</Typography>
        </StyledNoResults>
    );
}
