import { EntryListItemActionProps } from "../entry-list-item/EntryListItemAction";
import { EventPlatformEntry } from "@kaltura/mediaspace-shared-types";
import EntryListItem from "./../entry-list-item/EntryListItem";

export interface EventPlatformEntryItem extends EventPlatformEntry {
    actions?: EntryListItemActionProps[];
}

/**
 * lock the generic type to `EventPlatformEntryItem[]`
 */
export interface EntriesListProps {
    content: EventPlatformEntryItem[];
}

/**
 * Entries list as used in personal profile and channel media sections.
 * Displays entry data, either scheduled or not, without scheduling info.
 */
function EntriesList({ content }: EntriesListProps) {
    const list = content.map((entry) => {
        const { name, description, duration, thumbnailUrl, id, schedulingData, actions } = entry;
        return (
            <EntryListItem
                key={`EntriesList${id}`}
                name={name}
                description={description}
                duration={duration}
                image={thumbnailUrl}
                entryId={id}
                schedulingData={schedulingData}
                actions={actions}
            />
        );
    });
    return <>{list}</>;
}

export default EntriesList;
