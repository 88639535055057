import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import BadgeIcon from "../../common/BadgeIcon";
import { BadgeProps } from "../../common/types";
import { useBadgesModal } from "../../modal/useBadgesModal";

type BadgeIconWrapperProps = {
    badge: BadgeProps;
};

/**
 * wrapper for BadgeIcon component
 * used for supporting the useBadgesModal custom hook
 */
const BadgeIconWrapper = (props: BadgeIconWrapperProps) => {
    const { name, iconUrl, description } = props.badge;

    const sendButtonAnalytics = useButtonAnalytics();

    const [toggleModal, Modal] = useBadgesModal({ title: name, badgeIconUrl: iconUrl, description: description });

    const handleIconClick = () => {
        sendButtonAnalytics("carousel - Header menu - Single Badge I earned", ButtonClickAnalyticsType.LAUNCH);
        toggleModal();
    };

    return (
        <>
            <BadgeIcon iconUrl={iconUrl} onAction={handleIconClick} showPointer={true} isSmall={true} />

            {Modal}
        </>
    );
};

export default BadgeIconWrapper;
