import { GapNotification } from "./types";
import { translate, translatePlural } from "@kaltura/mediaspace-shared-utils";
import {
    StyledNotificationButtons,
    StyledNotificationTitlePadded,
    StyledNotificationWithThumbnailContainer,
    Thumbnail,
} from "./NotificationContent";
import { TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import React from "react";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { Button } from "@kaltura/ds-react-components";

export interface GapBetweenSessionsNotificationProps {
    id: number;
    notification: GapNotification;
}

/**
 * Channel Tv - notification content notifying about a break until next session starts
 */
export const GapBetweenSessionsNotification = ({ notification, id }: GapBetweenSessionsNotificationProps) => {
    const { gapInMinutes, session, onBrowseSession, onGoToSession } = notification;
    const sendButtonAnalytics = useButtonAnalytics();

    const minutes = gapInMinutes % 60;
    const hours = Math.floor(gapInMinutes / 60);
    const formattedGap =
        hours === 0
            ? translatePlural("in %1 minute", "in %1 minutes", [minutes])
            : minutes === 0
              ? translatePlural("in %1 hour", "in %1 hours", [hours])
              : translate(
                    "%1 and %2",
                    [
                        translatePlural("in %1 hour", "in %1 hours", [hours]),
                        translatePlural("in %1 minute", "in %1 minutes", [minutes]),
                    ]
                );

    const browseSession = () => {
        sendButtonAnalytics(
            "TV guide notification - Break - Browse sessions",
            ButtonClickAnalyticsType.EXPAND,
            notification.analyticsEntryId
        );
        onBrowseSession(id);
    };

    const goToSession = () => {
        sendButtonAnalytics(
            "TV guide notification - Break - Go to session",
            ButtonClickAnalyticsType.LINK,
            notification.analyticsEntryId
        );
        onGoToSession(session, id);
    };

    return (
        <>
            <StyledNotificationTitlePadded>
                {translate("Next session is %1", [formattedGap])}
            </StyledNotificationTitlePadded>
            <StyledNotificationWithThumbnailContainer>
                <Thumbnail src={session.thumbnailUrl} />
                <TruncateWithTooltip lines={2} text={session.name}>
                    <span>{session.name}</span>
                </TruncateWithTooltip>
            </StyledNotificationWithThumbnailContainer>
            <StyledNotificationButtons>
                <Button variant={"pill"} color={"secondary"} size={"medium"} fullWidth={true} onClick={browseSession}>
                    {translate("Browse sessions")}
                </Button>
                <Button color={"primary"} size={"medium"} fullWidth={true} onClick={goToSession}>
                    {translate("Go to session")}
                </Button>
            </StyledNotificationButtons>
        </>
    );
};
