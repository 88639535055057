import React, { useState } from "react";
import { translate, baseUrl } from "@kaltura/mediaspace-shared-utils";
import { Tooltip } from "@kaltura/ds-react-components";
import { AddNewMenuButton } from "./AddNewMenuButton";
import { Add24Icon } from "@kaltura/ds-react-icons";
import { AddNewMenu as AddNewMenuBase } from "@kaltura/mediaspace-shared-add-new";
import { KmsTypeAddNewMenuSection } from "@kaltura/mediaspace-shared-types/addNew";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";

export interface AddNewMenuProps {
    createAsLogin?: boolean;
    sections?: KmsTypeAddNewMenuSection[];
    /**
     * prevent scroll bar appreaing, displacing absolutly positioned items.
     * used on legacy kms header.
     */
    disableScrollLock?: boolean;
}

/**
 *  `Add New` menu as used in the site header:
 *   - menu button.
 *   - menu positioning.
 */
export function AddNewMenu(props: AddNewMenuProps) {
    const { createAsLogin = false, sections, ...rest } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendAnalytics("Create - Header", ButtonClickAnalyticsType.MENU);
    };

    const sendAnalytics = (actionName: string, buttonType: ButtonClickAnalyticsType) => {
        sendButtonAnalytics(actionName, buttonType);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {createAsLogin && (
                <Tooltip title={translate("Login to create new media")} placement={"bottom"}>
                    <AddNewMenuButton
                        startIcon={<Add24Icon />}
                        component="a"
                        href={baseUrl + "/user/login"}
                        aria-label={translate("Login to create new media")}
                        role="link"
                        onClick={() => sendAnalytics("Create - Header", ButtonClickAnalyticsType.MENU)}
                    >
                        {translate("Create")}
                    </AddNewMenuButton>
                </Tooltip>
            )}
            {!createAsLogin && sections && sections.length > 0 && (
                <>
                    <AddNewMenuButton
                        id={"addNewMenuToggleBtn"}
                        aria-haspopup={true}
                        aria-expanded={open}
                        onClick={handleButtonClick}
                        aria-controls={"addNewMenu"}
                        aria-label={translate("Create")}
                        startIcon={<Add24Icon />}
                    >
                        {translate("Create")}
                    </AddNewMenuButton>
                    <AddNewMenuBase
                        sections={sections}
                        {...rest}
                        id={"addNewMenu"}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        aria-labelledby={"addNewMenuToggleBtn"}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    />
                </>
            )}
        </>
    );
}

export default AddNewMenu;
