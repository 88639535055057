import { useState } from "react";
import { MenuItem } from "@kaltura/ds-react-components";
import { baseUrl, getKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { useToastsContext } from "@kaltura/mediaspace-shared-contexts";
import { SharedMenuItemProps } from "@kaltura/mediaspace-shared-types";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

interface AddToWatchlistProps extends SharedMenuItemProps {
    entryId: string;
    categoryId?: string;
    inWatchlist?: boolean;
}

interface AddToWatchlistItemProps extends AddToWatchlistProps {
    label: string;
    disabled: boolean;
    onClick: () => void;
}

/**
 * Add to Watchlist menu item for media-page title area actions menu
 */
const AddToWatchlistItem = (props: AddToWatchlistItemProps) => {
    const { label, disabled, onClick, autoFocus } = props;

    return (
        <MenuItem
            disabled={disabled}
            onClick={onClick}
            autoFocus={autoFocus}
            className={"kms-ds-media-page-watchlist-button"}
        >
            {label}
        </MenuItem>
    );
};

/**
 * Add To Watch list item container - connect it to kms
 */
export const AddToWatchlistItemContainer = (props: AddToWatchlistProps) => {
    const { entryId, categoryId, onItemStateChange, onCloseMenu } = props;

    const [inWatchlist, setInWatchlist] = useState(props.inWatchlist);
    const [disabled, setDisabled] = useState(false);

    const { showToast } = useToastsContext();

    const label = inWatchlist ? translate("Remove from watch list") : translate("Add to watch list");

    const sendButtonAnalytics = useButtonAnalytics();

    const handleClick = async () => {
        const url =
            baseUrl
            + `/watchlater/media/`
            + (!inWatchlist ? `add/id/${entryId}` : `delete/id/${entryId}`)
            + (categoryId ? `/categoryid/${categoryId}` : "");

        try {
            setDisabled(true);
            const { result, message } = await getKmsData(url);

            // update state
            result && setInWatchlist((current) => !current);

            // show toast
            const severity = result ? "success" : "error";
            showToast({ severity: severity, message: message });

            // inform the menu
            result && onItemStateChange?.("inWatchlist", !inWatchlist);
            onCloseMenu?.();
        }
        catch (e: unknown) {
            showToast({ severity: "error", message: translate("Failed to update watch list.") });
            console.error(
                "Failed to add/delete add to watch later",
                e,
                {
                    url,
                }
            );
        }
        // send button click analytics
        sendButtonAnalytics(
            `Media Actions - ${inWatchlist ? "Remove from watch list" : "Add to watch list"}`,
            ButtonClickAnalyticsType.TOGGLE
        );
        // enable the button again
        setDisabled(false);
    };

    return <AddToWatchlistItem {...props} label={label} disabled={disabled} onClick={handleClick} />;
};
