import Thumbnail from "./thumbnail/Thumbnail";
import NameAndStatus from "./name-and-status/NameAndStatus";
import { StyledAccordion, StyledAccordionSummary, StyledFileRow } from "./StyleComponents";
import RowActions from "./row-actions/RowActions";
import { useMemo } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import EditForm from "./../../edit-form/EditForm";
import { EditFormElement, MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";

export interface FileRowProps {
    /**
     * info to show
     */
    fileInfo: MediaUploadFileInfo;

    /**
     * is edit panel currently open
     */
    isOpen: boolean;

    /**
     * should edit panel close
     */
    shouldClose: boolean;

    /**
     * list of form elements to show on edit form
     */
    editForm: EditFormElement[];

    /**
     * callback for cancel file upload button
     * @param uploadTokenId
     */
    onCancel: (uploadTokenId: string) => void;

    /**
     * callback for publish button
     * @param uploadTokenId
     */
    onPublish: (uploadTokenId: string) => void;

    /**
     * callback for edit button
     * @param uploadTokenId
     */
    onEdit: (uploadTokenId: string) => void;

    /**
     * callback for edit submit button
     * @param data
     */
    onSubmit: (data: Record<string, unknown>) => Promise<{ success: boolean; error?: string }>;

    /**
     * callback for "remove from list" button
     * @param uploadTokenId
     */
    onRemove: (uploadTokenId: string) => void;

    /**
     * callback for closing edit form
     * @param tokenId
     */
    onClose: (tokenId: string) => void;

    /**
     * used to omit the edit button from the actions row
     */
    showEditButton?: boolean;

    /**
     * used to omit the remove button from the actions row
     */
    showRemoveButton?: boolean;
}

const FileRow = (props: FileRowProps) => {
    const {
        fileInfo,
        isOpen,
        shouldClose,
        editForm,
        onSubmit,
        onClose,
        showEditButton = true,
        showRemoveButton = true,
        ...rest
    } = props;

    const actions = useMemo(
        () => (
            <RowActions
                {...rest}
                entryId={fileInfo.entry?.id}
                tokenId={fileInfo.fileMetaData.tokenId}
                status={fileInfo.fileMetaData.status}
                publishStatus={fileInfo.fileMetaData.publishStatus}
                isOpen={isOpen}
                showEditButton={showEditButton}
                showRemoveButton={showRemoveButton}
            />
        ),
        [
            fileInfo.entry?.id,
            fileInfo.fileMetaData.publishStatus,
            fileInfo.fileMetaData.status,
            fileInfo.fileMetaData.tokenId,
            rest,
            isOpen,
        ]
    );

    const entryId = fileInfo.entry?.id || "";
    const entryName = fileInfo.entry?.name || fileInfo.file?.name || "";

    const formData = Object.assign(
        {
            entryIds: entryId,
            fileTokenId: fileInfo.fileMetaData.tokenId,
            name: entryName,
            description: fileInfo.entry?.description || "",
            tags: fileInfo.entry?.tags || "",
        },
        fileInfo.entryMetadata || {}
    );

    return (
        <StyledAccordion TransitionProps={{ unmountOnExit: true }} disableGutters elevation={0} expanded={isOpen}>
            <StyledAccordionSummary id={`${entryId}-row`} aria-controls={`${entryId}-edit`}>
                <StyledFileRow isOpen={isOpen} className={"kms-ds-upload-media-file-row"}>
                    <Thumbnail file={fileInfo} />
                    <NameAndStatus name={entryName} metadata={fileInfo.fileMetaData} />
                    {actions}
                </StyledFileRow>
            </StyledAccordionSummary>
            <AccordionDetails>
                <EditForm
                    onSubmit={onSubmit}
                    formData={formData}
                    fieldsStructure={editForm}
                    shouldClose={shouldClose}
                    onClose={onClose}
                />
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default FileRow;
