import React from "react";
import styled from "@emotion/styled";
import { CenteredContentContainer, StyledTitle, VerticallyCenteredContainer, Wrapper } from "./StyledComponents";
import AnimatedAiIllustration from "@kaltura/mediaspace-shared-ui/animated-ai-illustration/AnimatedAiIllustration";
import { translate } from "@kaltura/mediaspace-shared-utils";
import TextSlider from "@kaltura/mediaspace-shared-ui/text-slider/TextSlider";
import { Typography } from "@kaltura/ds-react-components";

/**
 * Generate AI quiz loading state
 */
export const AiQuizSkeleton = () => {
    return (
        <Wrapper>
            <StyledTitle variant="h2" align={"center"} component={"h1"}>
                {translate("Create video quiz with AI")}
            </StyledTitle>
            <CenteredContentContainer>
                <VerticallyCenteredContainer>
                    <AnimatedAiIllustration />
                    <TextContainer>
                        <TextSlider>
                            <Typography variant={"h5"} component="span">
                                {translate("Doing our magic")}
                            </Typography>
                            <Typography variant={"h5"} component={"span"}>
                                {translate("Adding some glitter")}
                            </Typography>
                            <Typography variant={"h5"} component={"span"}>
                                {translate("Painting your vision")}
                            </Typography>
                            <Typography variant={"h5"} component={"span"}>
                                {translate("Creating innovative spells")}
                            </Typography>
                            <Typography variant={"h5"} component={"span"}>
                                {translate("Crafting wonders with skill")}
                            </Typography>
                        </TextSlider>
                    </TextContainer>
                </VerticallyCenteredContainer>
            </CenteredContentContainer>
        </Wrapper>
    );
};

const TextContainer = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(6),
}));
