import { KeyboardEvent, MouseEvent, useState } from "react";
import { Button, Menu, MenuItem } from "@kaltura/ds-react-components";
import { Calendar24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface MailMenuProps {
    entryId: string;
    items: { value: string; label: string }[];
    callback: (value: string, event: KeyboardEvent<HTMLAnchorElement> | null) => boolean;
}

export function MailMenu({ entryId, items, callback }: MailMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="kms-ds-event-list-item-menu">
            <Button
                id={`EventListItemCalendarToggleBtn-${entryId}`}
                variant={"borderless"}
                aria-haspopup={true}
                aria-expanded={open}
                aria-controls={`EventListItemCalendarToggleMenu-${entryId}`}
                onClick={handleButtonClick}
                className={`kms-ds-event-list-item-button-calendar`}
                size={"large"}
            >
                <Calendar24Icon />
                {translate("Add To Calendar")}
            </Button>
            <Menu
                id={`EventListItemCalendarToggleMenu-${entryId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                aria-labelledby={`EventListItemCalendarToggleBtn-${entryId}`}
            >
                {items.map((item, index) => (
                    <MenuItem
                        key={`menuitem-${entryId}-${index}`}
                        onClick={() => {
                            callback(item.value, null);
                            handleMenuClose();
                        }}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MailMenu;
