import React, { ReactElement, RefObject, useState } from "react";
import copy from "copy-to-clipboard";
import { Tooltip } from "@kaltura/ds-react-components";

interface Props {
    textRef?: RefObject<HTMLTextAreaElement>;
    text: string;
    toolTipLabel: string;
    children: ReactElement;
}

/**
 * Copy Button Component -  triggers a copy to clipboard functionality.
 *
 * Can work alone, or with CopiedText Component, connected via the ref prop.
 */
const CopyButton = (props: Props) => {
    const { text, toolTipLabel, children, ...passThroughProps } = props;
    const [copied, setCopied] = useState(false);

    const copyText = () => {
        // copy the text to clipboard
        copy(text);

        // mark state as copied - for the tooltip
        setCopied(true);

        setTimeout(
            function () {
                setCopied(false);
            },
            2000
        );

        // given a text element - focus and select it
        if (!props.textRef?.current) {
            return;
        }
        const target = props.textRef?.current;
        target.focus();
        target.select();
    };

    const elem = React.Children.only(children);

    return (
        <>
            <Tooltip title={toolTipLabel} placement={"top"} open={copied}>
                {React.cloneElement(
                    elem,
                    {
                        ...passThroughProps,
                        onClick: copyText,
                    }
                )}
            </Tooltip>
        </>
    );
};

export default CopyButton;
