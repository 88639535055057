import { createContext } from "react";
import { HeaderColor } from "@kaltura/mediaspace-shared-types/HeaderColor";

/**
 * HeaderMenuScrollContext Type
 */
export type HeaderMenuScrollContextType = {
    headerColor: HeaderColor;
    scrollRatio: number;
};

/**
 * context for scroll behaviour of menu items
 */
export const HeaderMenuScrollContext = createContext<HeaderMenuScrollContextType>({
    headerColor: "dark",
    scrollRatio: 0,
});
