import { useEffect, useMemo, useState } from "react";
import { Box, styled } from "@mui/material";
import {
    sidebarMobileBreakpoint,
    useIsMobileSidebarLayout,
    useSidebarLayoutContext,
    sidebarContentsWidth,
    sidebarButtonsSize,
    SidebarBaseButtonProps,
} from "@kaltura/ds-react-layouts";
import { CncProps } from "./CncProps";
import { useProcessedCncConfig } from "./useProcessedCncConfig";
import { KMS_GLOBAL } from "@kaltura/mediaspace-shared-utils";
import { useEventHandler } from "@kaltura/mediaspace-shared-hooks";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { ConfigSidebarButtonDescriptor } from "@kaltura/mediaspace-shared-data-kms-config/ConfigSidebarButton";
import { useEmbeddedCncWidgetContext } from "@kaltura/mediaspace-shared-contexts";

// region Styled components
const StyledSidebarTopButtons = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up(sidebarMobileBreakpoint)]: {
        width: "100%",
    },
}));
const StyledSidebarContents = styled(Box)({
    position: "absolute",
    inset: 0,
});
const StyledReactionsContainer = styled(Box)(({ theme }) => ({
    width: 48,
    height: 48,
    margin: theme.spacing(2),
}));

const StyledLiveReactionsContainer = styled(Box)({
    position: "relative",
});

const StyledFloatingReactions = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: 0,
    bottom: 0,
    [theme.breakpoints.up(sidebarMobileBreakpoint)]: {
        display: "none",
    },
}));
// endregion

/*
 * Parts of embedded C&C widget, component per DS layout region.
 * The mapping could be found in the ChatAndCollaboration module.
 */

/**
 * Initialize the widget object
 */
export const EmbeddedCncContainer = (props: CncProps) => {
    const { setIsOpened } = useSidebarLayoutContext();
    const { setCncWidget } = useEmbeddedCncWidgetContext();

    // Add config properties that depend on time/state
    const fulfilledConfig = useProcessedCncConfig(props);

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const cncWidget = useMemo(() => {
        let widget = null;
        try {
            if (props.serverUrl) {
                const unisphere = {
                    verbose: props.verbose,
                    serverUrl: props.serverUrl,
                    devOverrides: props.devOverrides,
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                widget = (window as any).KalturaEventsPlatform.collaboration.widgets.embedded.new({ unisphere });
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                widget = (window as any).KalturaEventsPlatform.collaboration.widgets.embedded.new();
            }

            KMS_GLOBAL.cncWidget = widget;
            return widget;
        }
        catch (err) {
            console.error("Failed to load chat widget: " + err);
            return undefined;
        }
    }, []);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        const onLoadFinished = () => setIsLoaded(true);
        const onClose = () => setIsOpened(false);

        cncWidget.addOnLoadFinished(onLoadFinished);
        cncWidget.addOnClose(onClose);

        cncWidget.renderCncContainer(container, fulfilledConfig);

        return () => {
            setIsLoaded(false);
            setIsOpened(false);

            cncWidget.removeOnLoadFinished(onLoadFinished);
            cncWidget.removeOnClose(onClose);

            cncWidget.unmountCncContainer();
        };
    }, [cncWidget, container, fulfilledConfig, setIsLoaded, setIsOpened]);

    useEffect(() => setCncWidget(isLoaded ? cncWidget : undefined), [cncWidget, setCncWidget, isLoaded]);

    return <div ref={setContainer} role={"presentation"} />;
};

/**
 * React hook for counting how many buttons are present in the sidebar except the C&C buttons
 */
const useNonCncButtonsCount = () => {
    const config = useConfig();
    const allButtonsCount = config?.dsLayout?.sidebarTopButtons?.length;
    // Don't count the C&C button - it's the current one
    return allButtonsCount ? allButtonsCount - 1 : 0;
};

export const EmbeddedCncSidebarTopButtons = ({ className, onActivate }: SidebarBaseButtonProps) => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const onActivateMemoized = useEventHandler(onActivate);

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const otherButtonsCount = useNonCncButtonsCount();

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderSidebarButtons(container, otherButtonsCount);
        cncWidget.addOnOpen(onActivateMemoized);

        return () => {
            cncWidget.unmountSidebarButtons();
            cncWidget.removeOnOpen(onActivateMemoized);
        };
    }, [cncWidget, container, otherButtonsCount, onActivateMemoized]);

    return <StyledSidebarTopButtons ref={setContainer} className={className} />;
};

export const EmbeddedCncSidebarBottomButtons = () => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const otherButtonsCount = useNonCncButtonsCount();

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderSidebarSettings(container, otherButtonsCount);

        return () => {
            cncWidget.unmountSidebarSettings();
        };
    }, [cncWidget, container, otherButtonsCount]);

    return (
        <>
            <EmbeddedCncReactionsDesktop />
            <div ref={setContainer} />
        </>
    );
};

export const EmbeddedCncSidebarContents = () => {
    const { cncWidget } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderCnc(container);

        return () => {
            cncWidget.unmountCnc();
        };
    }, [cncWidget, container]);

    return <StyledSidebarContents ref={setContainer} />;
};

export const EmbeddedCncSidebarButton: ConfigSidebarButtonDescriptor<Record<string, never>, Record<string, never>> = {
    id: "cnc",
    buttonComponent: EmbeddedCncSidebarTopButtons,
    buttonProps: {},
    contentsComponent: EmbeddedCncSidebarContents,
};

const CncReactionsBase = ({ isMobile = false, isOpened = false }) => {
    const { cncWidget, showReactions } = useEmbeddedCncWidgetContext();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [liveReactionsContainer, setLiveReactionsContainer] = useState<HTMLDivElement | null>(null);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isLiveReactionsAvailable, setIsLiveReactionsAvailable] = useState(false);

    useEffect(() => {
        if (!cncWidget) {
            return;
        }

        const onLoadFinished = () => setIsLoaded(true);

        cncWidget.addOnReactionsLoadFinished(onLoadFinished);

        return () => {
            cncWidget.removeOnReactionsLoadFinished(onLoadFinished);
        };
    }, [cncWidget, container, setIsLoaded]);

    useEffect(() => {
        if (!cncWidget || !isLoaded) {
            return;
        }

        if (showReactions) {
            cncWidget.startReactions();
        }
        else {
            cncWidget.endReactions();
        }
    }, [cncWidget, isLoaded, showReactions]);

    useEffect(() => {
        if (!cncWidget || !container) {
            return;
        }

        cncWidget.renderReactions(container);
        if (cncWidget.renderLiveReactions) {
            setIsLiveReactionsAvailable(true);
            cncWidget.renderLiveReactions(liveReactionsContainer);
        }

        /*
         * Note: we don't call unmountReactions() on dispose because the component is rendered in different places
         * according to the window width, and we have no control of the callback call order (we can't ensure that
         * unmountReactions of the previous location will be called before renderReactions of the new location)
         * same applies to unmountLiveReactions()
         */
    }, [cncWidget, container, liveReactionsContainer]);

    return (
        <>
            {isLiveReactionsAvailable && (
                <StyledLiveReactionsContainer
                    ref={setLiveReactionsContainer}
                    style={{
                        top: isMobile ? 48 : 0,
                        right: isMobile
                            ? 20
                            : isOpened
                              ? sidebarContentsWidth + sidebarButtonsSize + 20
                              : sidebarButtonsSize + 20,
                    }}
                    role={"presentation"}
                />
            )}
            <StyledReactionsContainer ref={setContainer} />
        </>
    );
};

/**
 * Reactions widget for the desktop layout (rendered as a part of the sidebar)
 */
export const EmbeddedCncReactionsDesktop = () => {
    const isMobile = useIsMobileSidebarLayout();
    const { isOpened } = useSidebarLayoutContext();
    if (isMobile) {
        return null;
    }

    return <CncReactionsBase isOpened={isOpened} />;
};

/**
 * Reactions widget for the mobile layout (rendered floating in the main region)
 */
export const EmbeddedCncReactionsMobile = () => {
    const isMobile = useIsMobileSidebarLayout();
    const { isOpened } = useSidebarLayoutContext();
    if (!isMobile || isOpened) {
        return null;
    }

    return (
        <StyledFloatingReactions>
            <CncReactionsBase isMobile={true} />
        </StyledFloatingReactions>
    );
};
