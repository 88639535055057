import styled from "@emotion/styled";
import React, { useState } from "react";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types/KmsTypePresenter";
import { Popper } from "@kaltura/mediaspace-shared-ui";
import EventPresenter from "../event-presenter/EventPresenter";
import Counter from "../counter/Counter";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";

export interface EventPresentersListProps {
    presenters: KmsTypePresenter[];
    isTranslucent?: boolean;
    // Show only the thumbnails, no details
    compact?: boolean;
    className?: string;
    // Which place the presenters are rendered in? (for analytics)
    analyticsDescription: string;
    // Which entry the presenters belong to?
    entryId?: string;
}

const StyledEventPresentersList = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey) {
            return propName !== "itemsCount" && propName !== "hasMore" && propName !== "compact";
        },
    }
)<{ itemsCount: number; hasMore: boolean; compact?: boolean }>(({ theme, itemsCount, hasMore, compact }) => ({
    display: "grid",
    width: "max-content",
    maxWidth: "100%",
    gap: theme.spacing(compact ? 1 : 2),
    gridTemplateColumns: `repeat(${itemsCount}, minmax(0, 1fr)) ${hasMore ? "auto" : ""}`,
}));

const EventPresenterListPopup = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: 260,
}));

const getPresentersElements = (
    presenters: KmsTypePresenter[],
    isTranslucent = false,
    compact = false,
    inPopover = false
) => {
    return presenters.map((presenter: KmsTypePresenter) => {
        return (
            <EventPresenter
                key={"presenter" + presenter.id}
                data={presenter}
                isTranslucent={isTranslucent}
                inPopover={inPopover}
                compact={compact}
            />
        );
    });
};

/**
 * return content of popover - list of presenters.
 * @param presenters
 */
export const getPresentersPopoverBody = (presenters: KmsTypePresenter[]) => {
    const presentersElements = getPresentersElements(presenters, false, false, true);
    return <EventPresenterListPopup>{presentersElements}</EventPresenterListPopup>;
};

export function EventPresentersList({
    presenters,
    isTranslucent,
    compact,
    className,
    analyticsDescription,
    entryId,
}: EventPresentersListProps) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const sendButtonAnalytics = useButtonAnalytics();

    const [isShowingAdditionalSpeakers, setIsShowingAdditionalSpeakers] = useState(false);
    const togglePresentersPopover = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.preventDefault();
        ev.stopPropagation();

        setIsShowingAdditionalSpeakers((show) => !show);

        sendButtonAnalytics(analyticsDescription + " - Expand speakers", ButtonClickAnalyticsType.OPEN, entryId);
    };
    const counterRef = React.useRef(null);

    const visiblePresentersCount = isLargeScreen || compact ? 2 : 1;
    const visiblePresenters = presenters.slice(0, visiblePresentersCount);
    const presentersElements = getPresentersElements(visiblePresenters, isTranslucent, compact);

    const additionalPresenters = presenters.slice(visiblePresentersCount);

    const presentersPopOverBody = React.useMemo(
        () => getPresentersPopoverBody(additionalPresenters),
        [additionalPresenters]
    );

    const hasMore = additionalPresenters.length > 0;

    return (
        <StyledEventPresentersList
            className={className}
            itemsCount={presentersElements.length}
            hasMore={hasMore}
            compact={compact}
        >
            {presentersElements}
            {hasMore && (
                <React.Fragment>
                    <Counter
                        ref={counterRef}
                        data={additionalPresenters.length}
                        onClick={togglePresentersPopover}
                        variant={isTranslucent ? "translucent" : "normal"}
                        roundness={theme.kaltura.shape.roundness1}
                    />
                    <Popper
                        flip={false}
                        open={isShowingAdditionalSpeakers}
                        placement={"top"}
                        anchorEl={counterRef.current}
                        onClose={() => setIsShowingAdditionalSpeakers(false)}
                        transition={false}
                    >
                        {presentersPopOverBody}
                    </Popper>
                </React.Fragment>
            )}
        </StyledEventPresentersList>
    );
}

export default EventPresentersList;
