import { forwardRef, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { LiveAutoComplete } from "../form/live-auto-complete/LiveAutoComplete";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";
import { Container, StyledFormLabel } from "./CommonStyles";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface TagsFieldProps {
    id: string;
    label: string;
    url: string;
    description?: string;
    value?: string;
}

/**
 * tags input field for edit entry form
 */
const TagsField = forwardRef<HTMLInputElement, TagsFieldProps>(({ id, label, url, description, value }, _ref) => {
    const { control } = useFormContext();

    const [currentValues, setCurrentValues] = useState<LabelledValue[]>([]);

    const getTags = useCallback(
        (tags: string | undefined): LabelledValue[] => {
            return tags?.length
                ? tags
                      .trim()
                      .split(",")
                      .map((tag) => {
                          return {
                              value: tag.trim(),
                              label: tag.trim(),
                          };
                      })
                : [];
        },
        []
    );

    const handleChange = (formOnChange: (...event: any[]) => void) => {
        return (_event: SyntheticEvent<Element, Event>, newValue: LabelledValue[]) => {
            // put updated value on form model
            const newval = newValue.map((tag) => tag.value).join(",");
            formOnChange(newval);
        };
    };

    useEffect(() => {
        setCurrentValues(getTags(value));
    }, [value]);

    return (
        <Container>
            <StyledFormLabel htmlFor={id} text={label} description={description} />
            <Controller
                control={control}
                name={id as "dummy"}
                defaultValue={value}
                render={({ field: { onChange, onBlur, value } }) => (
                    <LiveAutoComplete
                        id={id}
                        url={url}
                        loadingText={translate("Searching...")}
                        // noOptionsText={translate('No groups found')}
                        value={getTags(value)}
                        options={currentValues}
                        inputRef={_ref}
                        onChange={handleChange(onChange)}
                        onBlur={onBlur}
                        minInputLength={3}
                        freeSolo={true}
                    />
                )}
            />
        </Container>
    );
});

export default TagsField;
