import { Typography, TypographyProps } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
import ReactHtmlParser from "@kaltura/mediaspace-shared-utils/html-react-parser";
import { BaseFormLabelClasses, getBaseFormLabelClass } from "./baseFormLabelClasses";
import { composeClasses } from "@kaltura/mediaspace-shared-styled";
import clsx from "clsx";

export { baseFormLabelClasses } from "./baseFormLabelClasses";

export interface BaseFormLabelProps extends Pick<TypographyProps, "variant"> {
    className?: string;
    classes?: Partial<BaseFormLabelClasses>;
    text: string;
    htmlFor?: string;
    id?: string;
    required?: boolean;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: "block",
        marginBottom: theme.spacing(1),
    },
}));

const useUtilityClasses = ({ classes }: Partial<BaseFormLabelProps>) => {
    const slots = {
        root: ["root"],
        label: ["label"],
    };

    return composeClasses(slots, getBaseFormLabelClass, classes);
};

/**
 * Form Label
 */
export const BaseFormLabel = (props: BaseFormLabelProps) => {
    const { htmlFor, id, text, required, className, variant = "body2" } = props;
    const classes = useUtilityClasses(props);

    return (
        <label htmlFor={htmlFor} id={id} className={clsx(classes.root, className)}>
            <StyledTypography variant={variant} component={"span"} className={classes.label}>
                {ReactHtmlParser(String(text))}
                {required ? " *" : ""}
            </StyledTypography>
        </label>
    );
};
