import { ComponentDefinition } from "./RegisterComponent";

const timestamp = Date.now();

/**
 *  component factory - implements the singleton and abstract factory patterns
 *
 *  - singleton - through the singleton enforcer pattern http://stackoverflow.com/a/26227662/1527470
 *  - abstract factory - each component is its own abstract factory method
 *
 *
 * This Component Factory allows developers to register their React components separately, and then use them arbitrarily
 * in other places.
 * Each component can declare that it's implementing an "interface" by assigning a static property on the component (string[] to allow a component to implement multiple interfaces),
 * called "objectTypes".
 */
export class ComponentFactory {
    componentMap: Record<string, ComponentDefinition> = {};

    private static instance: ComponentFactory | null = null;

    constructor(enforcer: number) {
        // enforce creation of instance only through getInstance()
        if (enforcer !== timestamp) {
            throw new Error("Cannot construct singleton");
        }
    }

    static getInstance() {
        if (!ComponentFactory.instance) {
            ComponentFactory.instance = new ComponentFactory(timestamp);
        }

        return ComponentFactory.instance;
    }

    registerComponent(component: ComponentDefinition, id: string) {
        this.componentMap[id] = component;
    }

    getComponent(id: string) {
        return this.componentMap[id];
    }

    getAll() {
        return Object.keys(this.componentMap).map((key) => this.componentMap[key]);
    }

    /**
     * Returns the components that implement an interface.
     */
    getByObjectType(objectType: string) {
        return this.getAll()
            .filter((component) => !!component)
            .filter((component) => component.objectTypes?.includes(objectType));
    }
}
