import { forwardRef, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import MultiSelect from "../form/multi-select/MultiSelect";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";

export interface MultiSelectFieldProps {
    label: string;
    id: string;
    description?: string;
    value?: string[];
    options: string[];
}

/**
 * multi-select input field for edit entry form
 */
const MultiSelectField = forwardRef<any, MultiSelectFieldProps>((props: MultiSelectFieldProps, ref) => {
    const { label, id, description, value, options: opts } = props;

    const { control } = useFormContext();

    const enhanceValue = (value: string[]) =>
        value.map((v) => {
            return { value: v, label: v };
        });

    const options = useMemo(() => {
        return enhanceValue(opts);
    }, [opts]);

    const handleChange = (formOnChange: (...event: any[]) => void) => {
        return (value: LabelledValue[]) => {
            // on the form we only want a string array, not objects
            const flat = value.map((item) => item.value);
            formOnChange(flat);
        };
    };

    return (
        <Container>
            <StyledFormLabel htmlFor={id} text={label} description={description} />
            <Controller
                control={control}
                name={id as "dummy"}
                defaultValue={value || []}
                render={({ field: { onChange, onBlur, value } }) => (
                    <MultiSelect
                        id={id}
                        value={enhanceValue(value)}
                        ref={ref}
                        onChange={handleChange(onChange)}
                        onBlur={onBlur}
                        options={options}
                    />
                )}
            />
        </Container>
    );
});

export default MultiSelectField;
