import React, { Suspense, useMemo, useEffect } from "react";
import { baseUrl, fetchKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { GalleriesTabForm, GalleriesTabFormProps } from "./GalleriesTabForm";
import { CategorySearchSkeleton } from "../category-search/CategorySearchSkeleton";
import { NoResults } from "../category-search/no-results/NoResults";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";

interface GalleriesTabSearchResultsFormProps extends GalleriesTabFormProps {
    searchPublishGalleries: () => { results: KmsTypePublishCategory[] };
}

/**
 * galleries tab search results checkboxes
 */
function GalleriesTabSearchResultsForm(props: GalleriesTabSearchResultsFormProps) {
    const { searchPublishGalleries } = props;
    const { results } = searchPublishGalleries();

    const sendButtonAnalytics = useButtonAnalytics();

    useEffect(
        () =>
            sendButtonAnalytics(
                `Publish - Set publishing settings modal box - search for gallery`,
                ButtonClickAnalyticsType.SEARCH
            ),
        [sendButtonAnalytics]
    );

    const hasResults = results && results.length > 0;

    return (
        <>
            {hasResults && <GalleriesTabForm {...props} userCategories={{}} galleryResults={results} />}
            {!hasResults && <NoResults title={translate("No galleries found")} />}
        </>
    );
}

interface GalleriesTabFormContainerProps extends GalleriesTabFormProps {
    keyword: string;
}

/**
 * Publish modal Galleries tab suspense Container - handles galleries search
 */
export function GalleriesTabFormContainer(props: GalleriesTabFormContainerProps) {
    const { keyword } = props;

    // fetch data from kms
    const searchPublishGalleries = useMemo(
        () =>
            keyword
                ? fetchKmsData<{ results: KmsTypePublishCategory[] }>(
                      baseUrl + "/publishmedia/category/search/",
                      {
                          keyword: keyword,
                          type: "galleries",
                      }
                  )
                : undefined,
        [keyword]
    );

    return (
        <>
            {!keyword && <GalleriesTabForm {...props} />}
            {keyword && (
                <ErrorBoundary fallback={<CategorySearchSkeleton />}>
                    <Suspense fallback={<CategorySearchSkeleton />}>
                        <GalleriesTabSearchResultsForm searchPublishGalleries={searchPublishGalleries!} {...props} />
                    </Suspense>
                </ErrorBoundary>
            )}
        </>
    );
}
