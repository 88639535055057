import { ComponentFactory } from "./ComponentFactory";
import { ComponentType } from "react";

export type ComponentDefinition = ComponentType<any> & {
    objectTypes?: string[];
};

/**
 * register a component to the ComponentFactory.
 * use as you would use a HOC - export default registerComponent(MyComponent, id);
 */
export function registerComponent(WrappedComponent: ComponentDefinition, id: string) {
    ComponentFactory.getInstance().registerComponent(WrappedComponent, id);
    return WrappedComponent;
}
