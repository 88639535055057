import React, { useState } from "react";
import { PublishStatus } from "@kaltura/mediaspace-shared-types";
import { Button, ModalTitle } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { MediaCategoriesContainer } from "../../../media-categories/MediaCategoriesContainer";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../../../media-categories/CategoryLinkAnalyticsContext";
import {
    ModalProps,
    StyledModal,
    StyledModalActions,
    StyledModalContent,
    StyledModalDescription,
} from "../StyledModal";

/**
 * Publish menu (set as-)Private selection modal
 */
export function PrivateModal(props: ModalProps) {
    const { onClose, onSubmit, entryIds, publishStatus } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const [processing, setProcessing] = useState<boolean>(false);

    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const handleCancel = () => {
        isUnlisted
            && sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to private modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        onClose?.();
    };

    const handleSubmit = () => {
        isUnlisted
            && sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to private modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );

        setProcessing(true);
        onSubmit?.({ entryIds: entryIds, state: PublishStatus.PRIVATE });
    };

    const handleCategoryLinkAnalytics = () => {
        isUnlisted
            && sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to private modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
    };

    return (
        <StyledModal
            open={true}
            className="kms-ds-publish-modal-private"
            onMouseLeave={(event) => event.stopPropagation()}
        >
            <ModalTitle>{translate("Set media as private")}</ModalTitle>
            <StyledModalContent>
                {isUnlisted && (
                    <StyledModalDescription>
                        {translate(
                            "Are you sure you want to set this media as private? It will no longer be available for users in search results or with a link."
                        )}
                    </StyledModalDescription>
                )}
                {isPublished && (
                    <>
                        <StyledModalDescription>
                            {translate(
                                "Are you sure you want to set this media as private? It will be removed from all published locations in this application."
                            )}
                        </StyledModalDescription>
                        <CategoryLinkAnalyticsContext.Provider
                            value={{
                                sendCategoryLinkAnalytics: handleCategoryLinkAnalytics,
                                sendCategoryBubbleAnalytics: () => {},
                            }}
                        >
                            {entryId && <MediaCategoriesContainer entryId={entryId} />}
                        </CategoryLinkAnalyticsContext.Provider>
                    </>
                )}
            </StyledModalContent>

            <StyledModalActions>
                <Button variant={"borderless"} onClick={handleCancel} disabled={processing}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={handleSubmit} loading={processing} disabled={processing} size={"medium"}>
                    {translate("Save")}
                </Button>
            </StyledModalActions>
        </StyledModal>
    );
}
