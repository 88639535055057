import { styled } from "@mui/material";
import { MenuItem, NestedMenuItem } from "@kaltura/ds-react-components";
import { LinkMenuItemProps } from "../link/LinkMenuItem";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { SharedMenuItemProps } from "@kaltura/mediaspace-shared-types";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

interface MenuItemProps extends LinkMenuItemProps {
    /**
     * formatted size string
     */
    size: string;
}

interface DownloadMediaItemProps extends SharedMenuItemProps {
    label: string;
    items: MenuItemProps[];
}

const StyledDuration = styled("span")(({ theme }) => ({
    color: theme.kaltura.palette.tone3,
    marginLeft: theme.spacing(1),
}));

/**
 * Download media menu item for media-page title area actions menu
 */
export const DownloadMediaItem = (props: DownloadMediaItemProps) => {
    const { label, items, autoFocus } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const onMenuButtonClick = (actionName: string, buttonType: ButtonClickAnalyticsType) => {
        sendButtonAnalytics(actionName, buttonType);
    };

    return (
        <NestedMenuItem
            label={label}
            icon={<ChevronRight16Icon />}
            autoFocus={autoFocus}
            onClick={() => onMenuButtonClick("Media Actions - Download menu", ButtonClickAnalyticsType.MENU)}
        >
            {items
                && items.map((item: MenuItemProps, index: number) => {
                    const { href, label, size } = item;

                    return (
                        <MenuItem
                            key={index}
                            href={href}
                            component="a"
                            onClick={() =>
                                onMenuButtonClick("Media Actions - Download item", ButtonClickAnalyticsType.DOWNLOAD)
                            }
                        >
                            {label}
                            <StyledDuration>{size}</StyledDuration>
                        </MenuItem>
                    );
                })}
        </NestedMenuItem>
    );
};
