// libs/components/src/lib/components/Sidebar/ToggleMenuButton/ToggleMenuButton.tsx

import React from "react";
import SidebarItem from "../SidebarItem/SidebarItem";
import { Button } from "@kaltura/ds-react-components";
import { ChevronLeft24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import { SidebarMenuItem } from "@kaltura/mediaspace-shared-types/SidebarMenuItem";

export type MenuProps = {
    title: string;
    label?: string;
    currentMenu: SidebarMenuItem[];
    focusedItem: number;
    openSidebar: boolean;
    handleClickBackButton: () => void;
    handleClickSubMenuIcon: (menuItem: SidebarMenuItem) => void;
    handleKeyDownSubMenuIcon: (e: React.KeyboardEvent<HTMLButtonElement>, menuItem: SidebarMenuItem) => void;
    handleKeyDownBackButton: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    handleKeyDownMenuItem: (e: React.KeyboardEvent<HTMLElement>, index: number, menuItem: SidebarMenuItem) => void;
};

const BackButton = styled(Button)(({ theme }) => {
    return {
        padding: theme.spacing(3),
        borderRadius: 0,
        width: 320,
        [`&:hover`]: {
            backgroundColor: theme.kaltura.palette.tone7,
        },

        [`& .kms-ds-sidebar-menu-back-btn-label`]: {
            fontSize: theme.typography.htmlFontSize,
            fontWeight: theme.kaltura.typography.fontWeightBold,
            color: theme.kaltura.palette.tone1,
        },
    };
});

const StyledUl = styled.ul(({ theme }) => {
    return {
        margin: 0,
        height: "100vh",
        width: 320,
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: theme.kaltura.palette.tone8,
        padding: 0,
    };
});
/*
 * Sidebar Menu component - single visible menu inside the sidebar.
 */
const Menu = React.forwardRef<HTMLUListElement, MenuProps>(
    (
        {
            title = "",
            label,
            currentMenu = [],
            focusedItem,
            openSidebar,
            handleClickBackButton,
            handleClickSubMenuIcon,
            handleKeyDownSubMenuIcon,
            handleKeyDownBackButton,
            handleKeyDownMenuItem,
        },
        currentMenuList
    ) => {
        return (
            <div>
                <StyledUl ref={currentMenuList} className={"kms-ds-sidebar-menu"} role="menu" aria-label={label}>
                    {!!title && openSidebar && (
                        <BackButton
                            classes={{ label: "kms-ds-sidebar-menu-back-btn-label" }}
                            variant={"borderless"}
                            onClick={handleClickBackButton}
                            onKeyDown={handleKeyDownBackButton}
                            aria-label={translate("Back to %1", [title])}
                            startIcon={<ChevronLeft24Icon />}
                        >
                            {title}
                        </BackButton>
                    )}
                    {currentMenu.map((menuItem, index: number) => {
                        return (
                            <React.Fragment key={menuItem.id || JSON.stringify(menuItem)}>
                                <SidebarItem
                                    menuItem={{ ...menuItem, active: index === focusedItem }}
                                    handleClickSubMenuIcon={handleClickSubMenuIcon}
                                    handleKeyDownSubMenuIcon={handleKeyDownSubMenuIcon}
                                    index={index}
                                    keyDownItemHandler={handleKeyDownMenuItem}
                                    isSidebarOpen={openSidebar}
                                />
                            </React.Fragment>
                        );
                    })}
                </StyledUl>
            </div>
        );
    }
);

export default Menu;
